import { FC } from "react";
import * as React from "react";
import GHeader from "./generic/GHeader";
import GFooter from "./generic/GFooter";

const App: FC = () => {
  return (
    <div>
      <GHeader />
      <article>
        <div className="container my-4">
          <h1 className="name">DMCA</h1>
          <div
            className="row p-4 pb-0 align-items-center rounded-3 border shadow-lg"
            style={{ minBlockSize: "374px" }}
          >
            <p>
              Hyenso complies with the Digital Millennium Copyright Act (DMCA)
              and immediately suspends the content from access upon proper
              notification to you.
            </p>

            <p>
              To file a notice of copyright infringement with Hyenso, you will
              need to send a written notice that includes substantially the
              required information set forth in Section 512(c)(3) of the Digital
              Millennium Copyright Act to info@Hyenso.com. Upon review of the
              notice, Hyenso is obligated to remove, delete or suspend the
              content that allegedly infringes these rights.
            </p>

            <p>
              Copyright is a form of protection so that creators and subsequent
              owners of original works can control how the works are used.
              Copyright infringement occurs when a copyrighted work is
              reproduced, distributed, performed, publicly performed, or made
              into a derivative work without the permission of the copyright
              owner. Violation of the copyright owner's rights may lead to the
              suspension of that content in your Hyenso account, a suspension
              strike and account suspension. These acts may be of a criminal
              nature. In addition, copyright owners may take civil action
              against you and monetary damages may possibly be awarded.
            </p>

            <p>
              All content posted is the direct responsibility of the user who
              posts it as stated in our terms and conditions of use.
            </p>
          </div>
        </div>
      </article>
      <GFooter />
    </div>
  );
};

export default App;

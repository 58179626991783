import { FC } from "react";
import * as React from "react";
import GHeader from "./generic/GHeader";
import GFooter from "./generic/GFooter";

const App: FC = () => {
  return (
    <div>
      <GHeader />
      <article>
        <div className="container my-4">
          <h1 className="name">FAQs</h1>
          <div
            className="row p-4 pb-0 align-items-center rounded-3 border shadow-lg"
            style={{ minHeight: "374px" }}
          >
            <h4>What can I store on this site?</h4>
            <p>You can save any type of text.</p>
            <h4>I have lost the edit code, how can I recover it?</h4>
            <p>
              For website security, if you do not have the EditCode we cannot
              verify if you are the owner of that paste.
            </p>
            <h4>What data does it store?</h4>
            <p>
              Hyenso does not store any kind of user data, such as ip,
              fingerprint, etc...
            </p>
            <h4>How can I delete a paste I have created?</h4>
            <p>We are sorry but this feature is temporarily disabled.</p>
            <h4>How long will my pastes be online?</h4>
            <p>
              If you comply with the site rules you should not worry about this.
              We still reserve the right to remove content that we feel is
              inappropriate.
            </p>
            <h4>How do I share my paste?</h4>
            <p>
              To share a paste, simply copy the url of the paste, you can also
              copy it with or without a password.
            </p>
            <h4>Who can see my pastes?</h4>
            <p>Only users who have access to the link.</p>
            <h4>What is the maximum paste size?</h4>
            <p>
              The maximum paste size is defined as 64Kilobytes, sufficient for
              any type of text.{" "}
            </p>
          </div>
        </div>
      </article>
      <GFooter />
    </div>
  );
};

export default App;

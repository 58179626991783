import { FC, useEffect, useState } from "react";
import * as React from "react";
import GHeader from "./generic/GHeader";
import GFooter from "./generic/GFooter";
import { useNavigate } from "react-router-dom";

const App: FC = () => {
  const navigate = useNavigate();
  const [token] = useState(localStorage.getItem("token"));

  useEffect(() => {
    if (token) {
      return navigate(`/profile`);
    }
  }, [token, navigate]);

  const [status, setStatus] = useState("LOGIN");

  const [nameRegister, setNameRegister] = useState("");
  const [emailRegister, setEmailRegister] = useState("");
  const [passwordRegister, setPasswordRegister] = useState("");

  const [emailLogin, setEmailLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");

  const [loadingButton, setLoadinButton] = useState(false);
  const createAccount = async () => {
    const result = await fetch(
      "https://api.tejeda.contact/v1/notefly/auth/signUp",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: nameRegister,
          email: emailRegister,
          password: passwordRegister,
        }),
      }
    );
    setLoadinButton(false);
    if (result.status !== 200) {
      return;
    }
    const resultPaste = await result.json();
    const token = resultPaste.data.token;
    if (token) {
      localStorage.setItem("token", token);

      return navigate(`/profile`);
    }
  };

  const loginAccount = async () => {
    const result = await fetch(
      "https://api.tejeda.contact/v1/notefly/auth/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: emailLogin,
          password: passwordLogin,
        }),
      }
    );

    setLoadinButton(false);
    if (result.status !== 200) {
      return;
    }
    const resultPaste = await result.json();
    const token = resultPaste.data.token;
    if (token) {
      localStorage.setItem("token", token);

      return navigate(`/profile`);
    }
  };

  return (
    <div className="d-flex flex-column ">
      <GHeader />

      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-4">
          <div style={{ width: "100%" }}>
            {/* Pills navs */}
            <div
              className="btn-group btn-group-toggle mb-1"
              style={{ width: "100%" }}
              data-toggle="buttons"
              id="opciones"
            >
              <input
                type="radio"
                name="options"
                id="Sing-In"
                className="btn-check"
                defaultChecked
                onClick={() => setStatus("LOGIN")}
              />
              <label className="btn btn-radioSelect" htmlFor="Sing-In">
                {" "}
                Sing-In
              </label>
              <input
                type="radio"
                name="options"
                id="Register"
                className="btn-check"
                onClick={() => setStatus("REGISTER")}
              />
              <label className="btn btn-radioSelect" htmlFor="Register">
                Register
              </label>
            </div>

            {/* Pills navs */}

            {/* Pills content */}
            <div className="tab-content">
              {status === "LOGIN" ? (
                <div
                  className="tab-pane fade active show"
                  id="pills-login"
                  role="tabpanel"
                  aria-labelledby="tab-login"
                >
                  {/* Email input */}
                  <div className="form-outline mb-4">
                    <label
                      className="form-label"
                      htmlFor="loginName"
                      style={{ marginLeft: 0 }}
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="loginName"
                      className="form-control"
                      onChange={(e) => setEmailLogin(e.target.value)}
                    />
                  </div>

                  {/* Password input */}
                  <div className="form-outline mb-4">
                    <label
                      className="form-label"
                      htmlFor="loginPassword"
                      style={{ marginLeft: 0 }}
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      id="loginPassword"
                      className="form-control"
                      onChange={(e) => setPasswordLogin(e.target.value)}
                    />
                  </div>

                  {/* Submit button */}

                  {/* Register buttons */}
                </div>
              ) : (
                <div
                  className="tab-pane fade  active show"
                  style={{}}
                  id="pills-register"
                  role="tabpanel"
                  aria-labelledby="tab-register"
                >
                  <div className="form-outline mb-4">
                    <label
                      className="form-label"
                      htmlFor="registerName"
                      style={{ marginLeft: 0 }}
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      id="registerName"
                      className="form-control"
                      onChange={(e) => setNameRegister(e.target.value)}
                    />
                  </div>

                  {/* Email input */}
                  <div className="form-outline mb-4">
                    <label
                      className="form-label"
                      htmlFor="registerEmail"
                      style={{ marginLeft: 0 }}
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="registerEmail"
                      className="form-control"
                      onChange={(e) => setEmailRegister(e.target.value)}
                    />
                  </div>

                  {/* Password input */}
                  <div className="form-outline mb-4">
                    <label
                      className="form-label"
                      htmlFor="registerPassword"
                      style={{ marginLeft: 0 }}
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      id="registerPassword"
                      className="form-control"
                      onChange={(e) => setPasswordRegister(e.target.value)}
                    />
                  </div>

                  {/* Checkbox */}
                  <div className="form-check d-flex mb-4">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      id="registerCheck"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="registerCheck">
                      I have read and agree to the terms
                    </label>
                  </div>
                </div>
              )}
              <button
                style={{ width: "100%" }}
                id="submit"
                className="btn btn-create mb-3"
                disabled={loadingButton}
                onClick={status === "LOGIN" ? loginAccount : createAccount}
              >
                {loadingButton ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : status === "LOGIN" ? (
                  "Sign in"
                ) : (
                  "Register"
                )}
              </button>
              <div
                className="alert alert-danger"
                role="alert"
                id="notification"
                style={{ display: "none" }}
              >
                Error
              </div>
            </div>
          </div>
        </div>
      </div>

      <GFooter />
    </div>
  );
};

export default App;

import { FC, useCallback, useEffect, useState } from "react";
import * as React from "react";
import GHeader from "./generic/GHeader";
import GFooter from "./generic/GFooter";
import "../../App.css";
import { useNavigate, useParams } from "react-router-dom";
import * as showdown from "showdown";
var xssFilter = require("showdown-xss-filter");

var converter = new showdown.Converter({
  simpleLineBreaks: true,
  simplifiedAutoLink: true,
  parseImgDimensions: true,
  backslashEscapesHTMLTags: true,
  ghCodeBlocks: true,
  emoji: true,
  tables: true,
  extensions: [xssFilter],
});

const App: FC = () => {
  const navigate = useNavigate();
  const [token] = useState(localStorage.getItem("token"));

  const { pasteId } = useParams<{ pasteId: string }>();
  const [password, setPassword] = useState<string | null>(null);
  const [needPasword, setNeedPasword] = useState<boolean>(false);
  const [paste, setPaste] = useState<{
    title: string;
    content: string;
  } | null>(null);
  const loadPaste = useCallback(() => {
    if (paste) return;
    fetch(`https://api.tejeda.contact/v1/notefly/pastes/${pasteId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...(password !== null ? { "secret-password": password } : {}),
        ...(token ? { authorization: "Bearer " + token } : {}),
      },
    })
      .then(async (data) => {
        switch (data.status) {
          case 404:
            return navigate(`/`);
          case 401:
            if (!paste) setNeedPasword(true);
            break;
          case 200:
            setNeedPasword(false);
            const result = await data.json();
            setPaste({
              title: result.data.paste.title ?? "",
              content: result.data.paste.content ?? "",
            });
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [navigate, pasteId, password, token, paste]);

  useEffect(() => {
    loadPaste();
  }, [pasteId, loadPaste]);

  const Modal: FC = () => {
    return (
      <div
        className="modal fade show"
        tabIndex={-1}
        id="password"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Password</h5>
            </div>
            <div className="modal-body">
              <div
                className="alert alert-danger alert-dismissible fade show"
                style={{ display: "none" }}
                role="alert"
              >
                <strong>A</strong>
              </div>
              <div className="input-group mb-3">
                <input
                  id="input"
                  type="password"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-default"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => {
                  const inputPassword =
                    document.querySelector<HTMLInputElement>("#input");
                  if (inputPassword) {
                    setPassword(inputPassword.value);
                  }
                  //loadPaste();
                }}
                type="button"
                className="btn btn-primary"
                id="btnSend"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <GHeader />
      <div>
        <article>
          <div className="container my-4">
            {paste ? (
              <h1 className="name">{paste.title}</h1>
            ) : (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Cargando...</span>
              </div>
            )}
            <div
              className="row p-4 pb-0  rounded-3 border shadow-lg"
              style={{ minHeight: "374px" }}
            >
              {paste ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(paste.content),
                  }}
                />
              ) : (
                <div />
              )}

              <div className="p-0 overflow-hidden align-self-end">
                {/* <a
                target="_blank"
                type="button"
                id="Report"
                style={{ borderColor: "transparent" }}
                className="btn float-end rounded-0"
              >
                Report
              </a>
              <a
                type="button"
                id="btnRAW"
                style={{ borderColor: "transparent" }}
                className="btn float-end rounded-0"
              >
                RAW
              </a>*/}
              </div>
            </div>
          </div>
        </article>
        {needPasword && !paste && <Modal />}
      </div>
      <GFooter />
    </div>
  );
};
export default App;

import { FC, useState } from "react";
import * as React from "react";
import GHeader from "./generic/GHeader";
import GFooter from "./generic/GFooter";
import * as showdown from "showdown";
import { useNavigate } from "react-router-dom";
import "../../App.css";
var xssFilter = require("showdown-xss-filter");

//import * as showdown from 'showdown';
function auto_grow(element: HTMLTextAreaElement) {
  //element.style.height = "5px";
  //element.style.height = element.scrollHeight + "px";
}

var converter = new showdown.Converter({
  simpleLineBreaks: true,
  simplifiedAutoLink: true,
  ghCodeBlocks: true,
  parseImgDimensions: true,
  backslashEscapesHTMLTags: true,
  emoji: true,
  tables: true,
  extensions: [xssFilter],
});

function radioChange(event: React.ChangeEvent<HTMLInputElement>) {
  const inpuText = document.querySelector<HTMLTextAreaElement>("#inputText");
  const previewShow = document.querySelector<HTMLDivElement>("#previewShow");
  if (inpuText && previewShow) {
    if (event.target.id === "Preview") {
      previewShow.style.minHeight = inpuText.offsetHeight + "px";
      inpuText.style.display = "none";
      previewShow.style.display = "block";
      previewShow.innerHTML = converter.makeHtml(inpuText.value);
    } else {
      inpuText.style.display = "block";
      previewShow.style.display = "none";
    }
  }
}

/*
var converter = new showdown.Converter({     
    simpleLineBreaks:true,
    simplifiedAutoLink:true,
    parseImgDimensions:true,
    backslashEscapesHTMLTags:true,
    emoji:true,
    extensions: ['xssfilter'] });*/

const App: FC = () => {
  const navigate = useNavigate();
  const [token] = useState(localStorage.getItem("token"));

  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [visibility, setVisibility] = useState<string>("PUBLIC");
  const [password, setPassword] = useState<string | null>(null);

  const createPaste = async () => {
    const result = await fetch("https://api.tejeda.contact/v1/notefly/pastes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(token ? { authorization: "Bearer " + token } : {}),
      },
      body: JSON.stringify({
        title: title,
        content: content,
        password: password,
        visibility: visibility,
      }),
    });
    const resultPaste = await result.json();
    const pasteId = resultPaste.data.paste.id;
    if (pasteId) {
      return navigate(`/paste/${pasteId}`);
    }
  };

  return (
    <div>
      <GHeader />
      <article>
        <div className="container my-4">
          <h1 className="name">New Paste</h1>
          <div
            className="row p-4 pb-0 align-items-center rounded-3 border shadow-lg"
            style={{ minHeight: "374px" }}
          >
            <div
              className="btn-group btn-group-toggle mb-1"
              data-toggle="buttons"
              id="opciones"
            >
              <input
                type="radio"
                name="options"
                id="Text"
                className="btn-check"
                defaultChecked
                onChange={radioChange}
              />
              <label className="btn btn-radioSelect" htmlFor="Text">
                {" "}
                Text
              </label>
              <input
                type="radio"
                name="options"
                id="Preview"
                className="btn-check"
                onChange={radioChange}
              />
              <label className="btn btn-radioSelect" htmlFor="Preview">
                {" "}
                Preview
              </label>
            </div>
            <div className="jumbotron rounded-0">
              <textarea
                name="pasteText"
                className="form-control"
                required
                id="inputText"
                rows={15}
                onChange={(e) => setContent(e.target.value)}
                onInput={(e) => auto_grow(e.target as HTMLTextAreaElement)}
              ></textarea>
              <div
                style={{ display: "none" }}
                className="p-2"
                id="previewShow"
              ></div>
            </div>

            <div className="bs-example">
              <div className="clearfix">
                <div className="form-group mb-1">
                  <label htmlFor="pasteName">Paste Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="pasteName"
                    required
                    maxLength={100}
                    id="pasteName"
                    placeholder="Paste Name"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>

                <div className="form-group mb-1">
                  <label htmlFor="pastPass">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="pastePass"
                    id="pastPass"
                    maxLength={64}
                    placeholder="Password (Optional)"
                    onChange={(e) =>
                      setPassword(e.target.value === "" ? null : e.target.value)
                    }
                  />
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="pasteExposure">Paste Exposure</label>
                  <select
                    className="form-select"
                    id="Typee"
                    aria-label="Default select example"
                    onChange={(e) => setVisibility(e.target.value)}
                  >
                    <option value={"PRIVATE"} selected>
                      Private
                    </option>
                    <option value={"PUBLIC"}>Public</option>
                  </select>
                  <small id="emailHelp" className="form-text text-muted">
                    Private codes are not displayed in the list of recent codes.
                  </small>
                </div>
                <button
                  id="submit"
                  className="btn btn-create mb-3"
                  style={{ width: "100%" }}
                  onClick={createPaste}
                >
                  Create
                </button>
                <div
                  className="alert alert-danger"
                  role="alert"
                  id="notification"
                  style={{ display: "none" }}
                >
                  Error
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <GFooter />
    </div>
  );
};

export default App;

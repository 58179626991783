import { FC } from "react";
import * as React from "react";
import GHeader from "./generic/GHeader";
import GFooter from "./generic/GFooter";

const App: FC = () => {
  return (
    <div>
      <GHeader />
      <article>
        <div className="container my-4">
          <h1 className="name">Terms and Conditions</h1>
          <div
            className="row p-4 pb-0 align-items-center rounded-3 border shadow-lg"
            style={{ minHeight: "374px" }}
          >
            <p>
              Welcome to Hyenso.com. If you continue to browse and use this
              website, you are agreeing to comply with and be bound by the
              following terms and conditions of use, which together with our
              privacy policy govern Hyenso.com's relationship with you in
              relation to this website. If you do not agree with any part of
              these terms and conditions, do not use our website.
            </p>

            <p>
              Hyenso.com presents an unmoderated collection of text and
              hyperlinks added by anonymous users from around the world.
              Hyenso.com does not endorse any content or links found within our
              servers and will not hesitate to remove any content that violates
              the conditions described on this page, once you tell us about it.
            </p>

            <p>
              The term Hyenso.com or ' Hyenso.com or 'us' or 'we' or 'us' or
              'our' refers to the owner of this website. The term 'you' refers
              to the user or viewer of our website.
            </p>

            <h3>
              The following types of content CANNOT be published or linked to on
              Hyenso.com
            </h3>
            <ul>
              <li>Stolen login details</li>
              <li>Stolen password lists</li>
              <li>Stolen source code</li>
              <li>Data obtained through illegal piracy</li>
              <li>Material con copyright</li>
              <li>Bank details, credit card information</li>
              <li>Personal information about anyone except you</li>
              <li>Spam links</li>
              <li>Material containing depictions of child abuse</li>
            </ul>

            <h3>
              The use of this website is subject to the following terms of use
            </h3>
            <ul>
              <li>
                The content of the pages of this website is for your general
                information and use only. It is subject to change without notice
              </li>
              <li>
                This website uses cookies to monitor browsing preferences
                through Google Analytics.
              </li>
              <li>
                Neither we nor any third parties provide any warranty or
                guarantee as to the accuracy, timeliness, performance,
                completeness or suitability of the information and materials
                found or offered on this website for any particular purpose. You
                acknowledge that such information and materials may contain
                inaccuracies or errors and we expressly exclude liability for
                any such inaccuracies or errors to the fullest extent permitted
                by law.
              </li>
              <li>
                Your use of any information or materials on this website is
                entirely at your own risk, for which we shall not be liable. It
                shall be your own responsibility to ensure that any products,
                services or information available through this website meet your
                specific requirements.
              </li>
              <li>
                This website contains material which is owned by or licensed to
                us. This material includes, but is not limited to, the design,
                layout, look, appearance and graphics. Reproduction is
                prohibited other than in accordance with the copyright notice,
                which forms part of these terms and conditions.
              </li>
              <li>
                All trademarks reproduced in this website that are not the
                property of, or licensed to, the operator are acknowledged on
                the website.
              </li>
              <li>
                This website includes links to other websites. These links are
                provided by anonymous third parties, who have no connection with
                us and have not been verified. All links within playpaste.com
                are not moderated unless we have been asked to review them. They
                do not signify that we endorse the websites. We have no
                responsibility for the content of the linked websites.
              </li>
            </ul>
          </div>
        </div>
      </article>
      <GFooter />
    </div>
  );
};

export default App;

import { FC, useEffect, useState } from "react";
import * as React from "react";
import GHeader from "./generic/GHeader";
import GFooter from "./generic/GFooter";

const App: FC = () => {
  const [token] = useState(localStorage.getItem("token"));

  const [pastes, setPastes] = useState<
    {
      id: string;
      title: string;
      createdAt: Date;
      requieredPassword: boolean;
    }[]
  >([]);

  if (pastes) {
  }

  useEffect(() => {
    fetch(`https://api.tejeda.contact/v1/notefly/pastes/list/account`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...(token ? { authorization: "Bearer " + token } : {}),
      },
    }).then(async (data) => {
      switch (data.status) {
        case 200:
          const result = await data.json();
          setPastes(
            result.data.pastes.map(
              (paste: { createdAt: string | number | Date }) => {
                return {
                  ...paste,
                  createdAt: new Date(paste.createdAt),
                };
              }
            )
          );
          break;
      }
    });
  }, [token]);
  return (
    <div>
      <GHeader />
      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-5">
              <div className="card mb-4">
                <div className="card-body text-center">
                  <img
                    src="/avatar.jpg"
                    alt="avatar"
                    className="rounded-circle img-fluid"
                    style={{ width: "150px" }}
                  />
                  <h5 className="my-3">{"userName"}</h5>
                  <div className="d-flex justify-content-center mb-2">
                    <button
                      type="button"
                      className="btn btn-create"
                      onClick={() => {}}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-danger ms-1"
                      onClick={() => {}}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <h1 className="name">
                <div
                  className="spinner-border spinner-border-sm"
                  id="loading"
                  role="status"
                  style={{ display: "none" }}
                ></div>
                Pastes
              </h1>
              <div className="card mb-4 mb-md-0">
                <div className="card-body">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Paste</th>
                        <th scope="col">Views</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody id="pastes">
                      {pastes.map((paste) => {
                        return (
                          <tr>
                            <th scope="row">
                              <a
                                style={{ textDecoration: "none" }}
                                href={`/paste/${paste.id}`}
                              >
                                {paste.requieredPassword ? (
                                  <svg
                                    style={{ fill: "#000000" }}
                                    height="24"
                                    viewBox="0 0 48 48"
                                    width="20"
                                  >
                                    <path d="M 24 4 C 19.599415 4 16 7.599415 16 12 L 16 16 L 12.5 16 C 10.019 16 8 18.019 8 20.5 L 8 39.5 C 8 41.981 10.019 44 12.5 44 L 35.5 44 C 37.981 44 40 41.981 40 39.5 L 40 20.5 C 40 18.019 37.981 16 35.5 16 L 32 16 L 32 12 C 32 7.599415 28.400585 4 24 4 z M 24 7 C 26.779415 7 29 9.220585 29 12 L 29 16 L 19 16 L 19 12 C 19 9.220585 21.220585 7 24 7 z M 24 27 C 25.657 27 27 28.343 27 30 C 27 31.657 25.657 33 24 33 C 22.343 33 21 31.657 21 30 C 21 28.343 22.343 27 24 27 z"></path>
                                  </svg>
                                ) : null}
                                {paste.title}
                              </a>
                            </th>
                            <td>{0}</td>
                            <td>
                              <a
                                href={`./edit?paste=${paste.id}`}
                                type="button"
                                className="btn btn-sm btn-create"
                              >
                                Edit
                              </a>
                              <button
                                onClick={() => {}}
                                type="button"
                                className="btn btn-sm btn-danger"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GFooter />
    </div>
  );
};

export default App;

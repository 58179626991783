import { FC, useState } from "react";

const App: FC = () => {
  const [token] = useState(localStorage.getItem("token"));
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top shadow">
        <div className="container">
          <a className="navbar-brand" href="/">
            <span className="fs-5 headerName">Hyenso</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className=" nav navbar-nav navbar-center">
              <li className="nav-item active">
                <a className="nav-link" href="/">
                  New Paste
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/pastes">
                  Public Pastes
                </a>
              </li>
              <li className="nav-item">
                {token ? (
                  <a className="nav-link" href="/profile">
                    My Profile
                  </a>
                ) : (
                  <a className="nav-link" href="/auth">
                    Login
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default App;

import { FC, useEffect, useState } from "react";
import * as React from "react";
import GHeader from "./generic/GHeader";
import GFooter from "./generic/GFooter";
import { useNavigate } from "react-router-dom";

const App: FC = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pastes, setPastes] = useState<
    {
      id: string;
      title: string;
      createdAt: Date;
      requieredPassword: boolean;
      accountName: string | null;
    }[]
  >([]);

  useEffect(() => {
    setShowLoading(true);
    fetch(
      `https://api.tejeda.contact/v1/notefly/pastes/list/public?page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(async (data) => {
      setShowLoading(false);
      switch (data.status) {
        case 200:
          const result = await data.json();
          setTotalPages(result.data.totalPages);
          setPastes(
            result.data.pastes.map(
              (paste: { createdAt: string | number | Date }) => {
                return {
                  ...paste,
                  createdAt: new Date(paste.createdAt),
                };
              }
            )
          );
          break;
      }
    });
  }, [page]);

  return (
    <div>
      <GHeader />

      <article>
        <div className="container my-4">
          <h1 className="name">
            {showLoading && (
              <div
                className="spinner-border spinner-border-sm"
                id="loading"
                role="status"
              ></div>
            )}
            Pastes
          </h1>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Title</th>
                <th scope="col">Owner</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              {pastes.map((paste) => {
                return (
                  <tr onClick={() => navigate(`/paste/${paste.id}`)}>
                    <td>
                      {paste.requieredPassword && (
                        <svg
                          style={{ fill: "#000000" }}
                          height="24"
                          viewBox="0 0 48 48"
                          width="20"
                        >
                          <path d="M 24 4 C 19.599415 4 16 7.599415 16 12 L 16 16 L 12.5 16 C 10.019 16 8 18.019 8 20.5 L 8 39.5 C 8 41.981 10.019 44 12.5 44 L 35.5 44 C 37.981 44 40 41.981 40 39.5 L 40 20.5 C 40 18.019 37.981 16 35.5 16 L 32 16 L 32 12 C 32 7.599415 28.400585 4 24 4 z M 24 7 C 26.779415 7 29 9.220585 29 12 L 29 16 L 19 16 L 19 12 C 19 9.220585 21.220585 7 24 7 z M 24 27 C 25.657 27 27 28.343 27 30 C 27 31.657 25.657 33 24 33 C 22.343 33 21 31.657 21 30 C 21 28.343 22.343 27 24 27 z"></path>
                        </svg>
                      )}

                      {paste.title}
                    </td>
                    <td>{paste.accountName}</td>
                    <td>
                      {new Intl.DateTimeFormat("es-ES", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(new Date(paste.createdAt))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="d-flex justify-content-center">
            <div id="link_table_paginate">
              <ul className="pagination">
                <li
                  className={`paginate_button page-item previous ${
                    page === 1 ? "disabled" : ""
                  }`}
                  id="link_table_previous"
                  onClick={() => setPage(Math.max(page - 1, 1))}
                >
                  <div className="page-link">Previous</div>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li
                    key={index + 1}
                    className={`paginate_button page-item ${
                      page === index + 1 ? "active" : ""
                    }`}
                    onClick={() => setPage(index + 1)}
                  >
                    <div className="page-link">{index + 1}</div>
                  </li>
                ))}
                <li
                  className={`paginate_button page-item next ${
                    page === totalPages ? "disabled" : ""
                  }`}
                  id="link_table_next"
                  onClick={() => page < totalPages && setPage(page + 1)}
                >
                  <div className="page-link">Next</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </article>
      <GFooter />
    </div>
  );
};
export default App;

import { FC } from "react";

const App: FC = () => {
  return (
    <footer className="py-3 mt-auto">
      <ul className="nav justify-content-center border-bottom pb-3 mb-3">
        <li className="nav-item">
          <a href="/" className="nav-link px-2 text-muted">
            Home
          </a>
        </li>
        <li className="nav-item">
          <a href="/terms" className="nav-link px-2 text-muted">
            Terms and Conditions
          </a>
        </li>
        <li className="nav-item">
          <a href="/dmca" className="nav-link px-2 text-muted">
            DMCA
          </a>
        </li>
        <li className="nav-item">
          <a href="/faq" className="nav-link px-2 text-muted">
            FAQs
          </a>
        </li>
      </ul>
      <p className="text-center text-muted">© 2024 Hyenso, Inc</p>
    </footer>
  );
};

export default App;

import { useRouteError } from "react-router-dom";
export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      id="error-page"
      style={{
        textAlign: "center",
        padding: "50px",
        borderRadius: "10px",
        color: "#721c24",
      }}
    >
      <h1 style={{ fontSize: "2.5em", fontWeight: "bold" }}>Oops!</h1>
      <p style={{ fontSize: "1.2em" }}>
        We're sorry, an unexpected error has occurred.
      </p>
      <p>
        <i style={{ fontStyle: "italic", color: "#856404" }}>
          {error.statusText || error.message}
        </i>
      </p>
    </div>
  );
}

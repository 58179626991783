import "./index.css";
//import App from "./pages/App";
//import Paste from "./pages/Paste";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./error-page";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import APP_OLD from "./pages/old/APP_OLD";
import TAC from "./pages/old/TAC";
import DMCA from "./pages/old/DMCA";
import FAQ from "./pages/old/FAQ";
import PASTES_OLD from "./pages/old/PASTES_OLD";
import PASTES_LIST from "./pages/old/PASTES_LIST";
import AUTH from "./pages/old/AUTH";
import PROFILE from "./pages/old/PROFILE";
const router = createBrowserRouter([
  {
    path: "/",
    element: <APP_OLD />, //<App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/paste/:pasteId",
    errorElement: <ErrorPage />,
    element: <PASTES_OLD />, //<Paste />,
  },
  {
    path: "/terms",
    errorElement: <ErrorPage />,
    element: <TAC />,
  },
  {
    path: "/dmca",
    errorElement: <ErrorPage />,
    element: <DMCA />,
  },
  {
    path: "/faq",
    errorElement: <ErrorPage />,
    element: <FAQ />,
  },
  {
    path: "/pastes",
    errorElement: <ErrorPage />,
    element: <PASTES_LIST />,
  },
  {
    path: "/auth",
    errorElement: <ErrorPage />,
    element: <AUTH />,
  },
  {
    path: "/profile",
    errorElement: <ErrorPage />,
    element: <PROFILE />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
